<template>
  <section>

    <div class="codeBox">
      <div class="mb-2">
        <h1>Login to continue</h1>
      </div>
      <!-- <otp-input
        ref="otpInput"
        input-classes="otp-input"
        separator="-"
        :num-inputs="4"
        :should-auto-focus="true"
        input-type="password"
        :is-input-num="true"
        @on-complete="handleOnComplete"
      /> -->

      <b-form
        class="p-2"
        @submit.prevent="handleOnComplete"
      >
        <b-form-input
          id="pass"
          v-model="pass"
          autofocus
          type="password"
          style="text-align: center;width: 250px;"
          trim
          placeholder="Enter your password"
        />
        <div>
          <span class="font-small-2">Press "Return/Enter" to continue</span>
        </div>
        <b-button
          class="mt-1"
          block
          variant="gradient-primary"
          @click="handleOnComplete"
        >
          Submit
        </b-button>
      </b-form>
      <b-spinner
        v-if="loading"
        class="ml-1"
        variant="primary"
        label="Loading..."
      />

    </div>
    <footer>
      <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25 p-md-2">
          <b-link
            class="ml-25"
            to="/login"
          >Go back and login by company</b-link>
        </span>

      </p>
    </footer>
  </section>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BSpinner, BLink, BForm, BFormInput, BButton,
} from 'bootstrap-vue'
// import OtpInput from '../components/otp/OtpInput.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BSpinner,
    // OtpInput,
    BLink,
    BForm,
    BButton,
    BFormInput,
  },
  data() {
    return {
      loading: false,
      tryTimes: 0,
      pass: '',
    }
  },

  methods: {
    async handleOnComplete() {
      this.loading = true
      const payload = {
        userInfo: {
          password: this.pass,
        },
        notify: this.$toast,
      }
      this.$store
        .dispatch('auth/loginByUser', payload)
        .then(() => {
          this.loading = false
        })
        .catch(error => {
          // eslint-disable-next-line no-underscore-dangle
          const _this = this
          _this.pass = ''
          _this.loading = false
          localStorage.setItem('loginAttemptTryTimes', _this.tryTimes)
          _this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: error.message.includes('pass') ? 'Password' : 'Account',
                icon: 'icon-alert-circle',
                text: error.message,
                variant: 'danger',
              },
            },
            {
              timeout: 2500,
              position: 'bottom-right',
            },
          )
          // check for fail retry
        })
    },
  },
}
</script>

<style lang="scss">
// body {
//    position: relative; /* usually the parent, in this case the body element, has position relative so that the absolute positioned child is positioned relative to it */
//     height: 100vh; /* 100% of the viewport height */
//     margin: 0; /* recommended */
// }

.codeBox {
  position: absolute; /* taken out of the normal flow of the document */
  top: 50%; /* moved down by 50% of the screen height */
  flex-direction: column;
  transform: translateY(
    -50%
  ); /* moved back up (Y axis) by half of its height to achieve the perfect center */
  width: 100%; /* needs to be defined to keep the default block behavior */
  text-align: center;
  margin: 0; /* again, for perfect center */
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
